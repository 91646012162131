<template>
    <div>
        <!-- <button @click="fetchAudio">Play Audio</button> -->
        <audio ref="audio" controls></audio>
    </div>
</template>

<script setup>
import { ref, defineExpose } from 'vue';
const axios = require('axios');
// 定义 ref 变量
const audio = ref(null);
const audioBlob = ref(null);
const fetchAudio = async (message) => {
    try {
        console.log(`Start TTS: ${message}`)
        const response = await axios.get(`https://dds.dui.ai/runtime/v1/synthesize?voiceId=juan1f&text=${message}&speed=0.25&volume=50&audioType=wav`, {
            responseType: 'blob'
        });
        audioBlob.value = response.data;
        //wait for 3 sec to play audio
        await new Promise((resolve) => setTimeout(resolve, 3000));
        playAudio();
    } catch (error) {
        console.error('Error fetching audio:', error);
    }
};

// 定义播放音频的方法
const playAudio = () => {
    if (audioBlob.value) {
        const audioUrl = URL.createObjectURL(audioBlob.value);
        audio.value.src = audioUrl;
        audio.value.play();
    }
};

// 暴露 fetchAudio 方法
defineExpose({ fetchAudio });
</script>

<style scoped>
/* Add your styles here */
</style>