<template>
  <h2>智慧相框技術雛形</h2>
  <p>支援JPG/JPEG/PNG 等格式圖檔上傳，其餘格式尚未開放</p>
  <div class="app-container">
    <div
      class="drag-area"
      :class="{ 'dragging': isDragging }"
      @dragover.prevent="handleDragOver"
      @dragleave.prevent="handleDragLeave"
      @drop.prevent="handleDrop"
    >
      <p v-if="!droppedImage">將圖片拖到這裡</p>
      <img v-else :src="droppedImage" alt="Dropped Image" class="dropped-image" />
    </div>
  </div>
  <p>{{ uploadMessage }}</p>
  <AudioPlayer ref="audioPlayer" />
  <footer>
    <p>iCenter International Inc.© 2024 - Smart Photo Frame ver.1.1.0 (2024/06/17)</p>
  </footer>
</template>

<script setup>
// eslint-disable-unused-vars
import { ref, watch } from 'vue';

const droppedImage = ref(null);
const isDragging = ref(false);
const uploadMessage = ref('Description of the uploaded image will appear here.');

import AudioPlayer from './AudioPlayer.vue';
const audioPlayer = ref(null);
const callTTS = (message) => {
  if (audioPlayer.value && audioPlayer.value.fetchAudio) {
    console.log(`call TTS: ${message}`); // 确认传递的参数
    audioPlayer.value.fetchAudio(message);
  } else {
    console.error("audioPlayer or fetchAudio is not available");
  }
}
const handleDragOver = () => {
  isDragging.value = true;
};

const handleDragLeave = () => {
  isDragging.value = false;
};

const handleDrop = async (event) => {
  isDragging.value = false;
  const files = event.dataTransfer.files;
  if (files.length) {
    // check the file size limition （20MB) from openai document
    if (files[0].size > 20 * 1024 * 1024) {
      // use the alert to show the warning
      alert('The file size is too large. Please upload a file smaller than 20MB.');
      return;
    }
    // check the file extension, jpg/jpeg/png
    const validExtensions = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!validExtensions.includes(files[0].type)) {
      // use the alert to show the warning
      alert('The file type is not supported. Please upload a JPG or PNG file.');
      return;
    }
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      droppedImage.value = e.target.result;
    };
    reader.readAsDataURL(file);

    // empty the description area, uploadMessage
    uploadMessage.value = 'Description of the uploaded image will appear here.';

    // Call the new uploadFile function
    await uploadFile(file);
  }
};
const uploadFile = async (file) => {
  // Create a FormData instance
  const formData = new FormData();
  // Append the file to the form data
  formData.append('uploadFile', file);

  // Make a POST request to the API
  const response = await fetch('/api/upload', {
    method: 'POST',
    body: formData
  });

  // Handle the response
  if (response.ok) {
    const data = await response.json();
    console.log(data.result);
    uploadMessage.value = data.result;
  } else {
    console.log('File upload failed');
  }
};

// watch for the UploadMessage value
// if the value changes, call the TTS function
watch(uploadMessage, (newValue) => {
  if (newValue!='Description of the uploaded image will appear here.')
    callTTS(newValue);
});

// onMounted(() => {
//   // Call the TTS function with the initial value of the uploadMessage
//   const message = '嘿，看這張照片，真的充滿了歡樂和期待呢！在這張照片裡，我看到了一家三口，似乎正在為一次愉快的旅行做準備。你們站在車的後備箱旁邊，車後門打開著，裡面裝滿了旅行的行李和必需品。\n每個人都戴著草帽，看起來既陽光又充滿旅遊氣息。孩子站在中間，雙手張開，臉上洋溢著幸福的笑容，簡直是感染了所有人的好心情呢！兩位大人也笑得十分燦爛，看起來對這次旅行充滿了期待和喜悅。\n後面的房子和藍天白雲，增加了整個畫面明亮和愉快的氣氛。這張照片給人一種溫暖的感覺，好像能聽到你們的笑聲和興奮地討論著旅行計劃的聲音。\n這真的讓人想起，和家人一起出遊的快樂時光，愉快的假期和對未來美好時光的憧憬。一家人在一起，這才是最重要的對吧？希望你們的旅行非常愉快，收穫滿滿的美好回憶！'
//   callTTS(message.replaceAll('\n', ' '));
// });
</script>

<style>
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.drag-area {
  width: 800px;
  height: 530px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  position: relative;
  transition: border-color 0.3s;
}

.drag-area.dragging {
  border-color: #66afe9;
}

.drag-area p {
  color: #999;
  font-size: 16px;
}

.dropped-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.images {
  display: flex;
  gap: 20px;
}

.draggable-image {
  width: 100px;
  height: 100px;
  cursor: grab;
}

.draggable-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>